import { isWcmEditor } from "config/serverConfig"
import { StockListComponenta } from "./stockSearchResults"
import * as axios from "axios"
import * as  debounce from "lodash/debounce"
import * as map from "lodash/map"

interface SearchProps {
    width: number

}
export type SearchType = "articles" | "stocks" | "video" | "internet"
export interface SearchList {
    type: SearchType
    title: string
}
export interface stockObj {
    sug: string
    linkto: string
}
interface SearchState {
    searchType: SearchType
    typedUserInput: string,
    stocksResultsValue: any[]
    stockValue: string
    selectedStock: stockObj
}
export class CalcalistSearchComponenta extends React.Component<SearchProps, SearchState> {
    constructor(props) {
        super(props)
        this.state = {
            searchType: "articles",
            typedUserInput: "",
            stocksResultsValue: null,
            selectedStock: null,
            stockValue: ""

        }
    }
    private updateInputValue = (evt) => {
        this.setState({ typedUserInput: evt.target.value });
    }

    private search = () => {
        const { searchType, typedUserInput } = this.state;

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let urlTail = ""

        if (!isWcmEditor()) {
            switch (searchType) {
                case "articles":
                    urlHead = "//www.calcalist.co.il/home/0,7340,L-3735,00.html"
                    userInput = typedUserInput
                    urlTail = "?cx=partner-pub-0047680307646695:9309925491&cof=FORID:10&ie=UTF-8&q="
                    fullUrl = urlHead + urlTail + userInput
                    location.href = fullUrl
                    break;
                case "video":
                    urlHead = "//www.calcalist.co.il/home/0,7340,L-3905,00.html?vstr="
                    userInput = typedUserInput
                    fullUrl = urlHead + userInput

                    location.href = fullUrl
                    break;
                case "internet":
                    urlHead = "//www.calcalist.co.il/home/0,7340,L-4560-2,00.html?"
                    userInput = typedUserInput
                    fullUrl = urlHead + userInput
                    location.href = fullUrl
                    break;
            }
        }

    }

    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }
    private selectedItemChoosed = (item) => {
        this.setState({ selectedStock: item })
    }

    private changeSearchType = (searchType: SearchType) => {
        this.setState({ searchType, typedUserInput: "" })
    }
    private getStockSearchResults = (value: string) => {
        this.setState({
            stockValue: value
        })
        if (value.length > 2) {
            axios.get(`/Common/Api/I-Invest/StockInfo/CmmApiIinvest_StocksFeed/0,15263,${value}.xml`, { responseType: 'document' })
                .then((res) => {
                    const items = (res.data as XMLDocument).getElementsByTagName("item");
                    const suggestions = [];
                    for (const item of items) {
                        suggestions.push({
                            sug: item.getElementsByTagName("sug")[0].innerHTML,
                            linkto: item.getElementsByTagName("linkto")[0].innerHTML
                        })
                    }

                    this.setState(
                        {
                            stocksResultsValue: suggestions,
                            selectedStock: suggestions[0]

                        }
                    );
                })
                .catch(err => console.error("error geting headline data:", err))
        }
        else return;
    }

    private getStockSearchResultsDebounced = debounce(this.getStockSearchResults, 500)

    render() {
        const { width } = this.props
        const { searchType, typedUserInput, stocksResultsValue, selectedStock, stockValue } = this.state
        let typeList: SearchList[] =
            [{ type: "articles", title: "כתבות" },
            { type: "stocks", title: "ניירות ערך" },
            { type: "video", title: "וידאו" },
            { type: "internet", title: "אינטרנט" }]
        let style = { width: `${width}px` }
        return (
            <div className="SearchArea" style={style}>
                <div className="searchContainer">
                    <ul className="searchTypeList">
                        {map(typeList, (item, i) =>
                            <li key={i} id={item.type} onClick={() => this.changeSearchType(item.type)} >
                                <a data-name={item.type} className={item.type === this.state.searchType ? "active" : ""}>{item.title}</a>
                            </li>
                        )}
                    </ul>
                    {searchType !== "stocks" && <div className="search-field">
                        <input
                            type="text"
                            id="queryString"
                            placeholder="חפש"
                            maxLength={25}
                            onKeyPress={this.handleInputKeyPress}
                            onChange={this.updateInputValue}
                            value={typedUserInput} />
                        <span className="search-btn"></span>
                    </div>}
                    {searchType === "stocks" && <div id="search_stock" className="search-field">
                        <input type="text" id="StockSuggest"
                            onChange={e => this.getStockSearchResultsDebounced((e.target as HTMLInputElement).value)}
                            placeholder="חפש נייר ערך או מדד" />

                        <span className="search-btn" />

                        {stocksResultsValue !== null &&
                            stockValue.length > 2 &&
                            <StockListComponenta
                                items={stocksResultsValue}
                                onChooseItem={(item) => this.selectedItemChoosed(item)} />}

                    </div>}
                </div>
            </div>
        )
    }
}
