import { SiteItemData } from "../../../../interfaces";
import { VideoGalleryVerticalMutam } from "./videoGalleryVerticalMutam";

export interface categoryObj {
    id: string
    categoryColor: string
    name:string
}

interface VideoGalleryVerticalMutamComponentaProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    firstAdPlace: number
    maxAds: number
    repeatAds: number
    shouldDisplayMobileAds: boolean
    category: categoryObj
    hideMoreButton:boolean 
    moreButtonText:string
    domain: string
}

export class VideoGalleryVerticalMutamComponenta extends React.Component<VideoGalleryVerticalMutamComponentaProps, {}>{
    public static siteScriptName = "VideoGalleryVerticalMutamComponenta";

    public render() {
        const { videoList, hideMoreButton,moreButtonText,  isTabVisible, tabTitleText, repeatAds, maxAds, firstAdPlace, shouldDisplayMobileAds, category, domain } = this.props;

        return (
            <div className="videoGalleryVerticalMutamComponenta">
                <VideoGalleryVerticalMutam
                    videoList={videoList}
                    isTabVisible={isTabVisible}
                    tabTitleText={tabTitleText}
                    repeatAds={repeatAds}
                    maxAds={maxAds}
                    firstAdPlace={firstAdPlace}
                    hideMoreButton={hideMoreButton}
                    moreButtonText={moreButtonText}
                    shouldDisplayMobileAds={shouldDisplayMobileAds}
                    category={category}
                    domain={domain}/>
            </div>
        )
    }
}
