
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { HamburgerMenuItems } from "../../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";


interface MainNavProps {
    mainNavItems: HamburgerMenuItems[]
    openHam: () => void
    className?:string

}

export class MainNavMutam extends React.Component<MainNavProps, {}> {
    private getSelectedClassName = (isSelected: boolean) => {
        if (isSelected) {
            return "selected"
        }
    }
    render() {
        const { mainNavItems, openHam, className } = this.props


        return (
            <div className={`mainNav ${className}`} role="navigation" aria-label="Main Menu">
                <div className="navList">
                    {_.map((mainNavItems), (item: HamburgerMenuItems, index: number) => <div key={index} >
                        <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} className={this.getSelectedClassName(item.isSelected)}>
                            <span dangerouslySetInnerHTML={{ __html: item.title }} />
                        </HyperLinkBuilder>
                    </div>)}</div>
                <button className="menuHamburger" onClick={openHam} />
            </div>
        )
    }
}