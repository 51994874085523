import { disablePageRefresh, enablePageRefresh, isPageNotReloaded } from "../siteWidgetUtils"
import { AUTOPLAY_HASH_TAG } from "../../widgetsUtils"
import { SiteVideoData, YnetTvSiteVideoData } from "../../interfaces"
export function moveEmbedToHeader(playerContainer: HTMLDivElement) {
    const header = playerContainer.querySelector(".fp-header");
    const embedButton = playerContainer.querySelector(".fp-embed");
    embedButton.setAttribute("title", "embed");
    embedButton.innerHTML = "";
    header.appendChild(embedButton)

}
export function moveShareToHeader(playerContainer: HTMLDivElement) {
    const header = playerContainer.querySelector(".fp-header");
    const shareButton = playerContainer.querySelector(".fp-share_button");
    header.appendChild(shareButton)

}

export function getVideoTitle(title: string, itemLinkUrl: string): string {
    if (title) {
        if (itemLinkUrl) {
            return `עבור ל: <a href="${itemLinkUrl}" target="_blank">${title}</a>`
        }
        return title;
    }
    return "";
}

export const isYouTubeGlobalSettingEnabled = (data: SiteVideoData | YnetTvSiteVideoData): boolean => {
    return false;
}


export function getVideoSources(mainUrl: string, downGradeUrl: string): { type: string, src: string }[] {
    const getVideoType = (url: string) => url.toLowerCase().endsWith(".m3u8") ? "application/x-mpegurl" : "video/mp4";
    const removeProtocolFromUrl = (url: string) => url.replace(/(^\w+:)/, '');
    const getSource = (url: string) => url ? ({ type: getVideoType(url), src: removeProtocolFromUrl(url) }) : undefined;
    const sources = []
    const mainSource = getSource(mainUrl)
    const downGradeSource = getSource(downGradeUrl);
    if (mainSource) sources.push(mainSource);
    if (downGradeSource) sources.push(downGradeSource);
    return sources
}

export function getLogoUrl() {
    const siteName = window && window["siteID"]
    switch (siteName) {
        case "ynet":
            return "//mediadownload.ynet.co.il/flowplayerlive/ynetlogo.png";
        case "vesty":
            return "https://www.vesty.co.il/images/ynetlvestylogo.png"
        case "ynetnews":
            return "https://ynet-pic1.yit.co.il/picserver5/wcm_upload/2019/03/28/SJ8Xwm9dN/CENTRAL_1024_ynet_logo_en.png"
        default:
            return "";
    }
}


export function addTitleToHeader(playerContainer: HTMLDivElement, title: string, itemLinkUrl: string) {
    const titleInnerHtml = getVideoTitle(title, itemLinkUrl);
    if (!titleInnerHtml) return;

    const header = playerContainer.querySelector(".fp-header");
    const titleElement = document.createElement("div");
    titleElement.className = "vp-title";
    titleElement.innerHTML = titleInnerHtml;
    header.appendChild(titleElement);
}

export function moveTitleToHeader(playerContainer: HTMLDivElement) {
    const header = playerContainer.querySelector(".fp-header");
    const title = playerContainer.querySelector(".fp-title");
    if (title) {
        header.appendChild(title);
    }

}
export function moveInfoToFixList(playerContainer: HTMLDivElement) {
    const playlist = playerContainer.querySelector(".fp-playlist");
    const info = playerContainer.querySelector(".fp-title");
    if (info) {
        playlist.appendChild(info);
    }

}

export function addAdsCloseButton(playerContainer: HTMLDivElement, api: any) {
    const adsElement = playerContainer.querySelector(".fp-ad-container .fp-ui");
    if (adsElement) {
        const anchorElement = document.createElement("a");
        anchorElement.className = "ynetfp-ads fp-icon";
        anchorElement.onclick = () => api.unload();
        adsElement.appendChild(anchorElement);
    }
}

export function attachPageRefrashUtils(api: any) {
    api
        .on("pause", () => enablePageRefresh())
        .on("resume", () => disablePageRefresh())
        .on("unload", () => enablePageRefresh())
}

export function attachPageRefreshUtilsYoutube(player: YT.Player) {
    player.addEventListener("onStateChange", (event: any) => {
        const player = event.target
        switch (event.data) {
            case YT.PlayerState.PLAYING:
                disablePageRefresh();
                break;

            case YT.PlayerState.ENDED:
            case YT.PlayerState.PAUSED:
                enablePageRefresh();
                break;
        }
    })
}


export function generateVideoId() {
    return `video_${Math.random()}`.replace(".", "");
}

export function shouldAutoPlayVideo(playerContainer: HTMLDivElement, isAutoplayMarkedByUser: boolean) {
    return !window['cancelArticleVideoAutoplay'] && (isAutoplayMarkedByUser && pageUrlContainsAutoplayHash() && isAboveTheFold(playerContainer) && isCorrectReferrer() && isPageNotReloaded());
}

function pageUrlContainsAutoplayHash() {
    return ~window.location.hash.indexOf(AUTOPLAY_HASH_TAG);
}

export function isAboveTheFold(element: HTMLElement) {
    //see http://ryanve.com/lab/dimensions/
    const videoElementTop = element.getBoundingClientRect().top
    const videoElementBottom = element.getBoundingClientRect().bottom
    const middleOfTheVideoElement = (videoElementBottom - videoElementTop) / 2
    return element.getBoundingClientRect().top + middleOfTheVideoElement <= document.documentElement.clientHeight;
}

function isCorrectReferrer() {
    const referrer = document.referrer;
    if (referrer) {
        const a = document.createElement("a");
        a.href = referrer;
        return window.location.hostname === a.hostname;
    } else {
        return false;
    }
}

export const windowIsWideEnoughForStickyVideo = () => {
    return window.innerWidth > 1400;
}
