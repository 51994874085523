import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink"
import { UserType } from "../../../../../users/interfaces"
import { YnetPlusUserTypeOptions, PremiumResourceIDs } from "../../../ynetSearch/components/ynetPianoLogin/ynetPianoLogin"



interface promoYplusBtnProps {
    link: string
    text: string
    linkTarget: LinkTargetProperties
    isBottomButton?: boolean

}
interface promoYplusBtnState {
    isUserLogged: boolean
    userType:YnetPlusUserTypeOptions
}

export class PromoYplusBtn extends React.Component<promoYplusBtnProps, promoYplusBtnState>{
    public static siteScriptName = "PromoYplusBtn";

    private isPianoLogin:boolean;

    constructor(props) {
        super(props as promoYplusBtnProps);
        this.state = {
            isUserLogged: false,
            userType:YnetPlusUserTypeOptions.GUEST
        }
    }

    componentDidMount() {

        this.isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;

        if (window && window.YitPaywall && window.YitPaywall.user) {
            this.setState({ isUserLogged: true })
        }


        if(this.isPianoLogin){
            const windowObj = typeof window != "undefined" && window;
            windowObj && windowObj.tp && windowObj.tp.push(["init", () => {
                const user = windowObj.tp.pianoId && windowObj.tp.pianoId.getUser();
                if (user) {
                    this.setState({userType:YnetPlusUserTypeOptions.REGISTERED})
                    windowObj.tp.api.callApi("/access/list", {}, (response: any) => {
                        response.data.forEach(activeResource=>{
                            if(PremiumResourceIDs.has(activeResource.resource.rid)){
                                this.setState({userType:YnetPlusUserTypeOptions.SUBSCRIBED})
                            }
                        });
                    });
                }else {
                    this.setState({userType:YnetPlusUserTypeOptions.GUEST})
                }
            }])
        }  
    }

    private analyticsPushPremium = (link: string, linkTarget: LinkTargetProperties, btnClassName: string) => {
        if (btnClassName == "bottomGoToLink") {
            let category = "Home Page CTR"
            let action = "Click on Componenta All ynet+ Articles button"
            let label = "button all articles"
            sessionStorage.setItem('Saved_Event_For_Tag_Manager', JSON.stringify({
                event: "GA_Event",
                Category: category,
                Action: action,
                Label: label
            }));
        }
        let target = "_self"
        if (linkTarget.linkOpenType && linkTarget.linkOpenType == 'new') {
            target = '_blank'
        }
        setTimeout(function () { window.open(link, target) }, 2000);
    }

    render() {

        const { text, link, linkTarget, isBottomButton } = this.props
        const { isUserLogged, userType} = this.state
        const btnClassName = isBottomButton ? "bottomGoToLink" : "promoBtn";

        const isPianoLogin = typeof window != "undefined" && window.YitPaywall && window.YitPaywall.isPianoLogin;
        const isUserAuthenticated = isPianoLogin? userType === YnetPlusUserTypeOptions.SUBSCRIBED : isUserLogged;
    




        if (isUserAuthenticated && !isBottomButton) return null;
        else if (text.length === 0) return null;
        else return (
            <button className={btnClassName} onClick={() => this.analyticsPushPremium(link, linkTarget, btnClassName)}>
                <div>{text}</div>
            </button>
        )

    }
}





