import { AudioPlayButton, AudioRendererProps, MediaMetadata } from "./../../radioWidgets/floatingPlayer/audioPlayButton";
import { ArticleData } from "../../../../articlePage/stateInterfaces";
import { ButtonRnederer } from "../../radioWidgets/floatingPlayer/podcastPlayerButton";
import { AodFloatingPlayer } from "../../radioWidgets/floatingPlayer/aodFloatingPlayer";
import "./radionas_site_radionasAudioPlayer.less"

interface RadionasAudioPlayerProps {
    audioUrl: string
    title: string
    durationSeconds: number
    className: string
    isMobileWeb?: boolean
    browserDisplayMediaMetaData?: MediaMetadata
    articleLink?: string
    articleData?: ArticleData
}

export function RadionasAudioPlayer(props: RadionasAudioPlayerProps) {
    const { className, title, articleLink, isMobileWeb } = props;

    return (
        <AudioPlayButton
            {...props}
            isLive={'false'}
            renderer={(props: AudioRendererProps) => (<ButtonRnederer {...props} className={className} />)}
            floatingPlayerRenderer={(props) => (
                <AodFloatingPlayer
                    {...props}
                    title={title}
                    site="radionas"
                    isMobileWeb={isMobileWeb}
                    articleLink={articleLink}
                    customCopyToClipBoardMessage="تم نسخ الرابط إلى الحافظة"
                />
            )}
        />
    )
}

RadionasAudioPlayer.siteScriptName = "RadionasAudioPlayer"