import * as classNames from "classnames"
import { stockObj } from "./searchArea"
require('./ctech_calcalist_site_search_area.less')

interface StockSearchProps {
    items: stockObj[]
    onChooseItem: (selectedItem: stockObj) => void
}

export class StockListComponenta extends React.Component<StockSearchProps, {}> {


    render() {
        const { items, onChooseItem } = this.props
        return (

            <div className="stockList">
                {items.length > 0 ? items.map((item, i) =>
                    <a className={classNames("stockItem")} onMouseOver={() => onChooseItem(item)} key={i} href={item.linkto}>
                        {item.sug}
                    </a>
                ) : <span className="noResult">לא נמצאו תוצאות</span>}
            </div>
        )


    }
}