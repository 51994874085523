
import * as map from "lodash/map"
import Slider from "react-slick"
import { isWcmEditor } from "config/serverConfig"
import * as classNames from "classnames"
import { RowTickerItemSlot } from "./rowTickerItemSlot";
import { ArticleExtraData } from "articlePage/stateInterfaces"
import { minutesDiff } from "./newsFlashAndTwentyFourSevenMobileComponenta";


interface RowTickerListProps {
    siteFlashList: ArticleExtraData[]
    seconds: number
    tabHref: string
    firstArticleId: string
    isFirstInterval: boolean
    shouldDisplayLoader?: boolean
    highlightingDuration? :number
    isShortMode?:boolean
}

interface ComponentState {
    openedItemId: string
    openedShareItemId: string
    rightPosition: number

}

const SampleArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}


export class RowTickerList extends React.Component<RowTickerListProps, ComponentState>{
    private slider;
    constructor(props) {
        super(props)
        this.state = {
            openedItemId: null,
            openedShareItemId: null,
            rightPosition: 0,

        }
    }



    private handleItemToggle = (articleId: string) => {
      //  if (this.state.openedItemId !== articleId) {
            this.setState({ openedItemId: this.state.openedItemId !== articleId ? articleId : null })
      //  }
      //  else this.setState({ openedItemId: null })

        this.dispatchCustomEvent(articleId);
    }

    private handleItemOpened = (articleId: string) => {
            if(this.state.openedItemId !== articleId){
                this.dispatchCustomEvent(articleId);
              }
              this.setState({ openedItemId:  articleId })
     

         
      }
    private dispatchCustomEvent = (articleId:string) => {
        const { openedItemId } = this.state;
        const { siteFlashList } = this.props;
        const openIndex = siteFlashList.findIndex(item => item.articleId === articleId);
        const title = siteFlashList[openIndex] && siteFlashList[openIndex].title;
        document.dispatchEvent(
            new CustomEvent("newsFlashesReadMoreChange",
                {
                    detail: {
                        action:  articleId===openedItemId ? "Collapse" : "Expand",
                        label: title
                    }
                }
            ))
    }
    private handleShareItemOpened = (articleId: string) => {

        if (this.state.openedShareItemId === articleId) {
            this.setState({ openedShareItemId: null })
        }
        else this.setState({ openedShareItemId: articleId })
    }
    private closeItem = () => {
        if(this.state.openedShareItemId!==null){
            this.setState({ openedShareItemId: null})
        }
        
    }
    componentDidMount() {
        window.addEventListener('scroll', this.closeItem);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.closeItem);
    }

    componentDidUpdate(prevProps: RowTickerListProps) {
        if (prevProps.shouldDisplayLoader !== this.props.shouldDisplayLoader && this.props.shouldDisplayLoader) {
            this.slider.slickGoTo(6)

        }
    }

    public render() {
        let { siteFlashList, seconds, tabHref, firstArticleId, isFirstInterval, shouldDisplayLoader, highlightingDuration, isShortMode } = this.props
        const { openedShareItemId, openedItemId, rightPosition } = this.state
        if (!siteFlashList || siteFlashList.length === 0) return null;
        const initialSlide = 2;//siteFlashList.findIndex(obj => obj.articleId == firstArticleId);

        let wrappedImageClassNames = isWcmEditor() ? "animationDiv isEditor" : "animationDiv tickerAnimation";

        let settings = {
            draggable: false,
            infinite: true,
            dots: false,
            variableWidth: true,
            // slidesToShow: 3.56,
            slidesToScroll: 1,
            autoplay: seconds > 0,
            autoplaySpeed: seconds * 1000,
            pauseOnHover: true,
            arrows: true,
            rtl: true,
            centerMode: false,
            beforeChange: (current, next) => {
                this.setState({ openedShareItemId: null, openedItemId: null })
                return this.setState({ rightPosition: siteFlashList && siteFlashList.length > 0 && (siteFlashList.length - 1 - current) * (1144 / siteFlashList.length) })
            },
            initialSlide: 3,
            nextArrow: <SampleArrow />,
            prevArrow: <SampleArrow />,

        }

        if (!siteFlashList || siteFlashList.length == 0) {
            return null
        }

        return (
            <div className={classNames("slotListRow")}  >
                <Slider {...settings} className={wrappedImageClassNames} ref={slider => (this.slider = slider)}>
                    {map(siteFlashList, (flashItem, i) => {
                        const newItem = minutesDiff(flashItem) < highlightingDuration;
                        return <RowTickerItemSlot
                            title={flashItem.title}
                            link={flashItem.publishedLink}
                            flashDate={flashItem.dateUpdatedOnSite}
                            roof={flashItem.roof}
                            key={i}
                            isShortMode={isShortMode}
                            author={flashItem.author}
                            articleId={flashItem.articleId}
                            flashText={flashItem.plainText}
                            tabHref={tabHref}
                            onToggleOpenItem={this.handleItemToggle}
                            onOpenItem={this.handleItemOpened}
                            onOpenShareItemButtons={this.handleShareItemOpened}
                            isFirstItem={firstArticleId === flashItem.articleId}
                            newItem={newItem}
                            openedItemId={openedItemId}
                            openedShareItemId={openedShareItemId}
                            isFirstInterval={isFirstInterval}
                            shouldDisplayLoader={shouldDisplayLoader && firstArticleId === flashItem.articleId}
                        />
                    })}
                </Slider>
            </div>
        )
    }
}