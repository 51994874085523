import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { Logo } from "./siteYnetHeaderComponenta";

interface MainNavProps {
    mainNavItems: HamburgerMenuItems[]
    onToggleMenu: () => void
    isOpened: boolean
    tabBgColor: string
    isSticky: boolean
    logoData: Logo
    onToggleSearch: () => void
    more:string 
    search:string
}

export class MainNavHeaderComponenta extends React.Component<MainNavProps, {}> {
    private getSelectedClassName = (isSelected: boolean) => {
        if (isSelected) {
            return "selected"
        }
    }
    render() {
        const { mainNavItems, isOpened, onToggleMenu, tabBgColor, isSticky, logoData, onToggleSearch, more, search } = this.props
        let defaultColors = { tabBgColor: "#de1a1a" }
        const tabBackgruondStyle = tabBgColor ? tabBgColor : defaultColors.tabBgColor;

        return (
            <div className="mainNav" role="navigation" aria-label="Main Menu" style={{ backgroundColor: tabBackgruondStyle }}>
                {isSticky && <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} ariaLabel={logoData.mediaTitle} className="ynetLogoInSticky" />}
                <div className="navList">
                {_.map((mainNavItems), (item: HamburgerMenuItems, index: number) => <div key={index} >
                    <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} className={this.getSelectedClassName(item.isSelected)} style={{ color: item.color, "--menuItemColor": item.color }}>
                        <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    </HyperLinkBuilder>
                </div>)}</div>
                <div onClick={onToggleMenu} id="wcm_MenuDisplay"><span className="moreBtn" id="wcm_MenuDisplay_moreBtn">{more}<span className={isOpened ? "moreIcon toClose" : "moreIcon toOpen"} /></span></div>
                <div onClick={onToggleSearch} id="wcm_SearchDisplay"><span className="searchIcon" id="wcm_MenuDisplay_searchIcon">{search}</span ></div>

            </div>
        )
    }
}