import { secondsToHms } from "../../../siteWidgets/siteWidgetUtils";
import Slider from "react-slick"
import { TogglePlayAction } from "./audioPlayButton";
import { ImaAd } from "./imaAd"
import { getDevice } from "../../../siteWidgets/videoPlayer/videoPlayerAds"
import * as ResizeObserver from "resize-observer-polyfill"
require("./ynet_pplus_magazine_site_aodFloatingPlayer.less")

export interface AodFloatingPlayerProps {
    isAudioPlaying: boolean
    playbackTime: number
    onClose: () => void
    audioElement: HTMLAudioElement
    durationSeconds: number
    isFloatingPlayerVisible: boolean
    isControlsDisabled: boolean
    isSeeking: boolean
    isMuted: boolean
    adDuration: number
    adPlaybackTime: number
    loadingSource: boolean
    onseeked: (playbackTime: number) => void
    handleMuteSwitch: () => void
    onTogglePlayPause: () => TogglePlayAction
}

interface AodFloatingPlayerAddedProps {
    title: string
    site?: string
    isMobileWeb?: boolean
    articleLink?: string
    customCopyToClipBoardMessage?: string
}

interface AodFloatingPlayerState {
    isTitleOverFlowing: boolean,
    programTitleWidth: string,
    floatingPlayerBottomHeight: number,
    popupMessageClassName: string,
}

const MUTAM_SCREEN_WIDTH = 640

export class AodFloatingPlayer extends React.Component<AodFloatingPlayerProps & AodFloatingPlayerAddedProps, AodFloatingPlayerState> {
    constructor() {
        super();
        this.state = {
            isTitleOverFlowing: null,
            programTitleWidth: "0vw",
            floatingPlayerBottomHeight: 0,
            popupMessageClassName: "hide",
        }
    }

    private resizeObserver: ResizeObserver;

    componentDidMount() {
        const elementId = this.props.isMobileWeb ? "ads.banner" : "ads.sargel"
        const stickyAdElement = document.getElementById(elementId)

        this.resizeObserver = new ResizeObserver(records => records.forEach(r => {
            this.onContentHeightChanged(r.contentRect.height)
        }))

        stickyAdElement && this.resizeObserver.observe(stickyAdElement);
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect()
    }

    private onContentHeightChanged = (height: number) => {
        this.setState({ floatingPlayerBottomHeight: height })
    }

    private onToggleAudioPlaying = () => {
        const { isAudioPlaying } = this.props
        isAudioPlaying ? this.props.audioElement.pause() : this.props.audioElement.play()
    }

    private jumpForward = () => {
        this.props.audioElement.currentTime += 15;
    }

    private jumpPrev = () => {
        this.props.audioElement.currentTime -= 15;
    }

    private updateCurrentTime = (e) => {
        this.props.onseeked(Number(e.target.value));
    }

    private pxToVw = (titleOffsetWidth: number) => {
        const calculatedWidth = (100 * titleOffsetWidth) / MUTAM_SCREEN_WIDTH
        return calculatedWidth + "vw"
    }

    private calculateIsTitleOverFlowing = (ref: HTMLDivElement) => {
        const { isMobileWeb } = this.props

        if (ref) {
            const wrapperElement = document.querySelector(".aod-player-show-title-wrapper") as HTMLElement
            const titleOverflow = wrapperElement ? ref.offsetWidth > wrapperElement.offsetWidth : false
            this.setState({
                isTitleOverFlowing: titleOverflow,
                programTitleWidth: isMobileWeb ? this.pxToVw(ref.offsetWidth) : ref.offsetWidth + "px"
            });
        }
    }

    private openNativeShare = async () => {
        const shareData = { url: this.props.articleLink, title: this.props.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    private handleCopyLink = () => {
        if (typeof navigator !== "undefined") {
            if (this.props.isMobileWeb) {
                this.openNativeShare();
            } else {
                navigator.clipboard.writeText(this.props.articleLink);
                this.setState({ popupMessageClassName: "show" })
                setTimeout(() => this.setState({ popupMessageClassName: "hide" }), 1000)
            }
        }
    }

    render() {
        const { isAudioPlaying, onClose, title, durationSeconds, audioElement, isFloatingPlayerVisible, isControlsDisabled, playbackTime, isSeeking, articleLink, isMobileWeb, customCopyToClipBoardMessage, site } = this.props
        const { isTitleOverFlowing, programTitleWidth, floatingPlayerBottomHeight, popupMessageClassName } = this.state

        const duration = audioElement && audioElement.src !== "" && audioElement.duration && audioElement.duration !== Infinity ? audioElement.duration : durationSeconds;
        const copyToClipBoardMessage = customCopyToClipBoardMessage ? customCopyToClipBoardMessage : "הקישור הועתק ללוח"
        const noShowsText = site === "radionas" ? "لا توجد خطة للعب" : "אין תוכנית לנגן"
        const percentage = (playbackTime - 0) * 100 / (duration - 0);
        const rangeBackground = `linear-gradient(90deg, #de1a1a ${percentage}%, #cacdce ${percentage}%)`;
        const mobileDeviceType = getDevice();
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: title.length * 200,
            autoplaySpeed: 0,
            rtl: true,
            arrows: false,
            cssEase: "linear"
        }

        return (
            <div className={`aod-floating-player-wrapper ${isFloatingPlayerVisible ? "show" : "hide"}`} style={{ bottom: floatingPlayerBottomHeight }} >
                <button className="aod-player-close-sign" onClick={onClose} aria-label="close player"></button>

                <div className="aod-floating-player">

                    <div className="aod-player-controls">
                        <button className={`aod-play-button ${isControlsDisabled || isSeeking ? "aod-play-button-loader" : ""}`} onClick={this.onToggleAudioPlaying} aria-label={isAudioPlaying ? `pause - ${title}` : `play - ${title}`}>
                            <div className={`aod-play-button-status ${isAudioPlaying ? "pause" : ""}`} ></div>
                        </button>

                        <button className={`forwardStep jumpto-button ${isControlsDisabled ? "disabled" : ""}`} onClick={this.jumpForward} disabled={isControlsDisabled} aria-label="skeep 15 seconds">{site === "radionas" ? "" : 15}</button>
                        <button className={`prevStep jumpto-button ${isControlsDisabled ? "disabled" : ""}`} onClick={this.jumpPrev} disabled={isControlsDisabled} aria-label="go backwards 15 seconds">{site === "radionas" ? "" : 15}</button>

                        <div className={`aod-player-controls-ruler-wrapper ${isControlsDisabled ? "disabled" : ""}`}>
                            <div className="aod-player-controls-ruler">
                                <div className="">
                                    <input type="range" className="timeline" max={duration} value={playbackTime} style={{ background: rangeBackground }} onChange={this.updateCurrentTime} disabled={isControlsDisabled} />
                                </div>

                                <div className="aod-player-durations">
                                    <span>{secondsToHms(playbackTime)}</span>
                                    <span>{secondsToHms(duration)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="aod-player-title-btn-container">
                        {articleLink &&
                            <div className="aod-player-copy-btn-wrapper">
                                <div className={`aod-player-copy-link-message ${popupMessageClassName}`}>{copyToClipBoardMessage}</div>
                                <button className="aod-player-copy-link-btn" onClick={this.handleCopyLink}>
                                    {isMobileWeb ?
                                        mobileDeviceType === "iphone" ? <div className="copy-link-iphone-icon"></div> : <div className="copy-link-android-icon"></div>
                                        :
                                        <div className="copy-link-desktop-icon"></div>
                                    }
                                </button>
                            </div>}

                        <div className="aod-player-show-title-wrapper">
                            {isTitleOverFlowing ?
                                <>
                                    <div className="fadder-right"></div>
                                    <div className="slider-wrapper" style={{ width: programTitleWidth }}>
                                        <Slider {...settings}>
                                            <div className={"aod-player-show-title"} dangerouslySetInnerHTML={{ __html: title || noShowsText }}></div>
                                            <div className={"aod-player-show-title"} dangerouslySetInnerHTML={{ __html: title || noShowsText }}></div>
                                        </Slider>
                                    </div>
                                    <div className="fadder-left"></div>
                                </> :
                                <div className={"aod-player-show-title"} ref={this.calculateIsTitleOverFlowing} dangerouslySetInnerHTML={{ __html: title || noShowsText }}></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}