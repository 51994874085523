import * as axios from "axios"
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { TickerViewData } from "../../../tickerAuto/interfaces";
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { ArticleExtraData } from "articlePage/stateInterfaces"
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { RowTickerList } from "./rowTickerList";

interface TickerAutoRowViewProps {
    data: Partial<TickerViewData>
    componentaId: string
    articles: ArticleExtraData[]
    displayType: string
}
interface TickerAutoRowViewState {
    tickerArticles: ArticleExtraData[]
    isFirstInterval: boolean
    shouldDisplayLoader: boolean
}


export class TickerAutoDesktopView extends React.Component<TickerAutoRowViewProps, TickerAutoRowViewState>  {
    public static siteScriptName = "TickerAutoDesktopView"

    static defaultProps = { isWar: false }
    constructor(props) {
        super(props)
        this.state = {
            tickerArticles: [],
            isFirstInterval: true,
            shouldDisplayLoader: false,
        }
    }

    componentDidMount() {
        const { data } = this.props;
        const highlightingDuration = data.highlightingDuration ? data.highlightingDuration : 3;
        if (data.category.link.id) {
            this.getTickerArticles(highlightingDuration)
            setInterval(() => {
                this.setState({ isFirstInterval: false })
                this.getTickerArticles(highlightingDuration)
            }, 60000);
        }
    }

    private getTickerArticles(highlightingDuration: number) {
        const { componentaId, data } = this.props;
        const { isFirstInterval, shouldDisplayLoader, tickerArticles } = this.state;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());

        axiosInstance.get(`/iphone/json/api/auto_ticker/${componentaId}`)
            .then((res: any) => {
                this.setState({ tickerArticles: res.data.data.tickerAutoDesktop.data });
            }).then(() => {
                const zeroMinDiff = this.state.tickerArticles.some((article) => minutesDiff(article) < 1);
                const upToFiveMinDiff = this.state.tickerArticles.some((article) => minutesDiff(article) < highlightingDuration);

                if ((isFirstInterval && upToFiveMinDiff) || zeroMinDiff) {
                    this.setState({ shouldDisplayLoader: true })
                    setTimeout(() => {
                        this.setState({ shouldDisplayLoader: false })
                    }, 2000)
                };
            })
            .catch(err => console.error("error geting ticker data:", err))
    }

    public render() {
        const { data, displayType } = this.props
        const { tickerArticles, isFirstInterval, shouldDisplayLoader } = this.state
        const highlightingDuration = data.highlightingDuration ? data.highlightingDuration : 3;
        const targetTitleLink: LinkTargetProperties = {
            linkOpenType: "same",
            lightBoxBgColor: "",
            lightBoxHeight: null,
            lightBoxOpacity: null,
            lightBoxWidth: 0
        }
        let itemList = [];
        const shiftValue = 9 - tickerArticles.length;

        itemList = tickerArticles;
        const isShortMode = displayType && displayType === "short";
        return (
            <div className={`TickerSliderComponenta ${displayType ? displayType : ""}`}>
                <HyperLinkBuilder className="titleArea" href={data.tabHref} linkTarget={data.tabTitleLinkTarget}>
                    <div className="tickerTitle">  {!isShortMode && <img className={"tickerBell"} src={"https://www.ynet.co.il/images/NewsFlash/Desktop_Bell_6_seconds_new.gif"} />} <span>{isShortMode ? "לכל המבזקים": "מבזקים"}</span></div>
                    {!isShortMode && <span className="gotoButton" >לכל העדכונים</span>}
                </HyperLinkBuilder>
                <div className="slotsContent">
                    <RowTickerList siteFlashList={itemList}
                        seconds={data.seconds}
                        highlightingDuration={highlightingDuration}
                        tabHref={data.tabHref}
                        firstArticleId={tickerArticles && tickerArticles.length > 0 ? tickerArticles[0].articleId : ""}
                        isFirstInterval={isFirstInterval}
                        shouldDisplayLoader={shouldDisplayLoader}
                        isShortMode={isShortMode}
                    />
                </div>

            </div>
        )
    }
}

function rotate(arr: Array<any>, moveBy: number) {
    const dest = [];
    let current = moveBy <= 0 ? Math.abs(moveBy) : arr.length - moveBy;
    for (let index = 0; index < arr.length; index++) {
        dest[current] = arr[index];
        current++;
        if (current === arr.length) current = 0;
    }
    return dest;
}

function reverse(src: Array<any>) {
    const dest = [];
    for (let index = src.length - 1; index >= 0; index--) {
        dest.push(src[index]);
    }
    return dest;
}

export const minutesDiff = (article: ArticleExtraData) => {
    return Math.floor((new Date().getTime() - article.dateUpdatedOnSite.getTime()) / (1000 * 60));
}