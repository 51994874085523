import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay";
import { useState, useRef, useEffect } from "react"
import { SiteVideoMedia } from "../../../../siteWidgets/siteMedia";
import { getFrontImageUrl } from "../../../../../front/staticServerRendering/frontImage";
import { ImageSize } from "../../../../../constants/imageSizes";
import { ArticleExtraData } from "../../../../../articlePage/stateInterfaces";
import { MediaContentTypes } from "../../../../../constants/contentTypes";
import { SiteVideoData } from "../../../../interfaces";
import { openFacebookShare, openWhatsAppShare, openTwitterShare } from "../../../../widgetsUtils";
import { SimpleAudioPlayer } from "../../simpleAudioPlayer/simpleAudioPlayer";

interface RadionasFlashItemProps {
    article: ArticleExtraData
    audioDomain: string
    isMobileWeb: boolean
    authorCaptionColor: string
    dateCaptionColor: string
    newsflashCaptionColor: string
    componentBackgroundColor: string
}

const TRANSITION_TIME = 400;

export const RadionasFlashItem = (props: RadionasFlashItemProps) => {

    const { article, isMobileWeb, authorCaptionColor, dateCaptionColor, newsflashCaptionColor, componentBackgroundColor, audioDomain } = props
    const [toggleOpen, setToggleOpen] = useState<boolean>(false)
    const [isShareMenuOpen, setIsShareMenuOpen] = useState<boolean>(false)
    const [shouldExpand, setShouldExpand] = useState<boolean>(false)
    const [itemHeight, setItemHeight] = useState<number>(0)
    const [textHeight, setTextHeight] = useState<number>(0)
    const itemRef = useRef<HTMLDivElement>()
    const accordionWrapperRef = useRef<HTMLDivElement>()
    const flashItemStyles = isMobileWeb ? { backgroundColor: componentBackgroundColor } : { minHeight: itemHeight, backgroundColor: componentBackgroundColor }
    const articleBodyTextStyles = toggleOpen ? { maxHeight: textHeight, color: newsflashCaptionColor } : { maxHeight: 0, marginTop: 0, color: newsflashCaptionColor }

    useEffect(() => {
        if (toggleOpen) {
            setShouldExpand(true)
        } else {
            const timer = setTimeout(() => setShouldExpand(false), TRANSITION_TIME)
            return () => {
                clearTimeout(timer)
            }
        }
    }, [toggleOpen])

    useEffect(() => {
        setItemHeight(itemRef.current.scrollHeight)
        setTextHeight(accordionWrapperRef.current.scrollHeight)
        console.log("updatedState", "itemRef: " + itemRef.current.scrollHeight, "accordionWrapperRef: " + accordionWrapperRef.current.scrollHeight);

    }, [itemRef.current, accordionWrapperRef.current])

    const getArticleMedia = () => {
        switch (article.specialData.mediaLinkType) {
            case "image":
                return <img src={getFrontImageUrl({ publishedLink: article.promotionImageDetails.publishedLink }, { imageSize: ImageSize.large })} />

            case "video":
                const videoData: SiteVideoData = {
                    mediaType: MediaContentTypes.MEDIA_VIDEO,
                    url: article.video[0].main_link,
                    downGradeUrl: article.video[0].low_res_link,
                    poster: article.video[0].posterSrc,
                    isPlayerBarVisible: article.specialData.videoBehaivior.isPlayerBarVisible,
                    isAutoPlaying: false,
                    isLoopPlaying: article.specialData.videoBehaivior.isLoopPlaying,
                    isMuteAvailable: article.specialData.videoBehaivior.isMuteAvailable,
                    isSoundButtonVisible: article.specialData.videoBehaivior.isSoundButtonVisible,
                    isBigVideo: true,
                    youtube_id: article.video[0].youtube_id,
                    mediaId: article.video[0].mediaVideoId,
                    blockAbroadViews: article.video[0].blockAbroadViews,
                    credit: article.video[0].credit,
                }

                return <SiteVideoMedia data={videoData} videoSettings={{ ...article.specialData.videoSettings, isVideoLightbox: false }} />

            case "audio":
                return <SimpleAudioPlayer src={`${audioDomain}/${article.specialData.audioNewsflashData.filePath}`} duration={article.specialData.audioNewsflashData.durationSeconds} />

            default:
                break;
        }
    }

    const handleShareBtnClick = () => {
        if (isMobileWeb) {
            openMobileShare()
        } else {
            setIsShareMenuOpen(!isShareMenuOpen)
        }
    }

    const openMobileShare = async () => {
        const shareData = { url: article.publishedLink, title: article.title }
        try {
            // @ts-ignore 
            await navigator.share(shareData)
        } catch (err) {
            console.log("error with sharing function", err);
        }
    }

    return (
        <div className="radionas-flash-item" style={flashItemStyles} ref={itemRef as any}>
            <div className={`flash-item-expandable-container ${shouldExpand ? "expanded" : ""}`} >
                <div className="flash-item-header">
                    <div className="author-details">
                        {article.firstAuthorImage ?
                            <img className="author-image" src={article.firstAuthorImage} />
                            :
                            <div className="author-image default-author-image"></div>
                        }

                        <div className="author-name" style={{ color: authorCaptionColor }}>{article.author}</div>
                    </div>

                    <DateDisplay date={article.dateUpdatedOnSite} style={{ color: dateCaptionColor }} />
                </div>

                <div className="flash-item-body">
                    {getArticleMedia()}

                    <div className="article-title">{article.title}</div>

                    <div className="article-body-text" style={articleBodyTextStyles} ref={accordionWrapperRef as any}>
                        {article.plainText}
                    </div>
                </div>

                <div className="flash-item-footer">
                    <div className={`toggle-btn icon ${toggleOpen ? "open" : ""}`} onClick={() => setToggleOpen(!toggleOpen)}></div>

                    <div className="share-btn icon" onClick={handleShareBtnClick}>
                        {isShareMenuOpen &&
                            <div className="share-menu">
                                <div className="twitter icon" onClick={() => openTwitterShare(article.publishedLink)}></div>
                                <div className="whatsapp icon" onClick={() => openWhatsAppShare(article.publishedLink)}></div>
                                <div className="facebook icon" onClick={() => openFacebookShare(article.publishedLink, article.title)}></div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
