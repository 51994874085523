import { RadioProgramItem } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"
import { RadioDayItemData } from "../../../radioWidgets/radioSchedule/data/radioDayItemData";
import { SingleShowItem } from "./singleShowItem";
import { DailyScheduleItem } from "./dailyScheduleItem";
import { useState } from "react"

interface NextShowsListProps {
    nextShow: RadioProgramItem
    isMobileWeb: boolean
    daysSchedule: RadioDayItemData[]
    radioScheduleList: RadioProgramItem[]
    numberOfVisibleDays: number
}

export const NextShowsList = (props: NextShowsListProps) => {

    const { nextShow, daysSchedule, radioScheduleList, isMobileWeb, numberOfVisibleDays } = props
    const [isTogglesOpen, setIsTogglesOpen] = useState(false)

    const onRadioBtnClick = (state: boolean) => {
        setIsTogglesOpen(state)
    }

    return (
        <div className="next-shows-list">
            {!isMobileWeb && <div className="radio-btn-wrapper">
                <input type="radio" className="radio-btn" checked={!isTogglesOpen} onChange={() => onRadioBtnClick(false)} />
                <div className="input-label close-toggles" style={{ marginLeft: 16 }}>العرض العادي</div>

                <input type="radio" className="radio-btn" checked={isTogglesOpen} onChange={() => onRadioBtnClick(true)} />
                <div className="input-label open-toggles">عرض ممتد</div>
            </div>}

            <div className="next-show-wrapper">
                <div className="next-show-title">البرنامج القادم</div>

                <SingleShowItem show={nextShow} isMobileWeb={isMobileWeb} />
            </div>

            <div className="daily-schedule-list-wrapper">
                {daysSchedule && daysSchedule.slice(0, numberOfVisibleDays).map(day =>
                    <DailyScheduleItem
                        dayItem={day}
                        key={day.dayItemId}
                        isMobileWeb={isMobileWeb}
                        isTogglesOpen={isTogglesOpen}
                        radioScheduleList={radioScheduleList}
                    />
                )}
            </div>
        </div>
    )
}
