
import { HyperLinkBuilder, SimpleLinkRenderer } from "widgets/commonComponents/inline/hyperLink"
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { Group } from "./siteMutamRadionasHeaderComponenta";
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"

interface MenuPopUpProps {
    groupsWithItems: Group[]
    isVisible: boolean
    onClose: () => void
    ynetSearchPageLink: string
    linkToTw: string
    linkToFb: string
    linkToYtube: string
    linkToInst:string
    followUsText:string
    layout?:string  
    domain?:string
    flashPageLink: string
    flashLinkTarget: LinkTargetProperties
    redMailLink: string
    mailLinkTarget: LinkTargetProperties
}
interface menuPopUpState {
    isSearchInputVisible: boolean
    userInput: string
}

export class HeaderScrolledMenu extends React.Component<MenuPopUpProps, menuPopUpState> {
    constructor(props) {
        super(props)

        this.state = {
            isSearchInputVisible: false,
            userInput: "",
        }

    }

    private updateInputValue = (evt) => {
        this.setState({ userInput: evt.target.value });
    }
    private search = () => {
        const { userInput: typedUserInput } = this.state;

        if (!typedUserInput) {
            return
        }

        let urlHead = ""
        let userInput = ""
        let fullUrl = ""
        let site = ""


        urlHead = 'https://www.google.com/search?radionas_q='
        userInput = typedUserInput
        site = `&q=site:www.radionas.co.il+${typedUserInput}`
        fullUrl = urlHead + userInput + site

        fullUrl
        window.open(fullUrl, '_blank');
    }


    private handleInputKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
            this.search();
        }

    }
    private clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    render() {
        const { groupsWithItems, isVisible,  onClose, linkToTw, linkToFb, linkToYtube, linkToInst, followUsText} = this.props
        const {redMailLink, mailLinkTarget, flashPageLink, flashLinkTarget} = this.props
        const {  userInput } = this.state
        const isVisibleClassName = isVisible ? "opened" : "closed";
        const isfollowUsLinks = linkToTw || linkToFb || linkToYtube || linkToInst
        return (
            <div className={`HeaderScrolledMenu  ${isVisibleClassName}`} role="sub-navigation" aria-label="Sub Menu">
                <div className="hamTopArea">
                    <div>
                    <div className="rightSideLinks">
                    <HyperLinkBuilder href={redMailLink} linkTarget={mailLinkTarget} >
                            <span className="headerIcon redMail" /> <span className="redMailText">البريد الأحمر</span>
                        </HyperLinkBuilder> 
                          <HyperLinkBuilder href={flashPageLink} linkTarget={flashLinkTarget} >
                            <span className="headerIcon flash" id="flashBell" /> <span className="flashText">فلاش المراسل</span>
                        </HyperLinkBuilder>
                        <div id="mainAccessibilityButton">
                            <a id="INDmenu-btn" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button" onClick={this.clickAcessibilityBtn}>

                            </a></div>
                        </div> 
                    <button className="closeBtn" onClick={onClose} />
                    </div>
                    <div className="searchArea">    
                             <input
                                alt="بحث"
                                className="mutamSrchBox"
                                id="mutamSrchBox"
                                aria-label="بحث"
                                type="text"
                                placeholder="بحث"
                                value={userInput}
                                onChange={this.updateInputValue}
                                onKeyPress={this.handleInputKeyPress}

                            />
                              <button className="srhBtn" onClick={()=>this.handleInputKeyPress} />
                        </div>
                </div>

                <div className="linksGroups">
               {_.map((groupsWithItems), (item: Group, index: number) =>
                        <div className="group" key={index}>
                            <div className="groupTitle" dangerouslySetInnerHTML={{ __html: item.groupName }} />

                            <ul className="groupList">
                                {_.map((item.items), (subItem: HamburgerMenuItems, index: number) =>
                                    <li key={index}><HyperLinkBuilder href={subItem.link} linkTarget={subItem.linkTarget} style={{ color: subItem.color }}>
                                        <span dangerouslySetInnerHTML={{ __html: subItem.title }} />
                                    </HyperLinkBuilder></li>
                                )}
                            </ul>
                        </div>
                    )}
                    {isfollowUsLinks &&  <div className="followUsLinks group">
                        {followUsText !== "" && <div className="followUs groupTitle">{followUsText}</div>}
                        <div className="mediaLinks">
                            {linkToTw !== "" && <SimpleLinkRenderer href={linkToTw} target="_blank" className="mediaIcon linkToTw" />}
                            {linkToFb !== "" && <SimpleLinkRenderer href={linkToFb} target="_blank" className="mediaIcon linkToFb" />}
                            {linkToInst !== "" && <SimpleLinkRenderer href={linkToInst} target="_blank" className="mediaIcon linkToInst" />}
                            {linkToYtube !== "" && <SimpleLinkRenderer href={linkToYtube} target="_blank" className="mediaIcon linkToYtube" />}
                        </div>
                    </div>}
                </div>

            </div>)
    }
}