import { MainMobileHeaderItem, SecondaryMobileHeaderItem, MenuSettings } from "./categoryHeaderMutamWrapper";
import { HyperLinkBuilder, SimpleLinkRenderer } from "../../../../../commonComponents/inline/hyperLink"
import { SiteSimpleImage } from "../../../../../commonComponents/inline/siteSimpleImage";


interface categoryHeaderMutamSideMenuProps {
    mainItems: MainMobileHeaderItem[]
    secondaryItems: SecondaryMobileHeaderItem[]
    showSideMenu: boolean
    menuSettings: MenuSettings
}

export const CategoryHeaderMutamSideMenu = (props: categoryHeaderMutamSideMenuProps) => {
    const { mainItems, secondaryItems, showSideMenu, menuSettings } = props
    const clickAcessibilityBtn = () => {
        window[`interdeal`] && window[`interdeal`][`a11y`] && window[`interdeal`][`a11y`].openMenu();
    }

    const termsLinkTarget = menuSettings.termsLinkTarget.linkOpenType === "new" ? "_blank" : "_top";
    const privacyLinkTarget = menuSettings.privacyLinkTarget.linkOpenType === "new" ? "_blank" : "_top";
    return (
        <div className={`category-header-mutam-side-menu ${showSideMenu ? "show" : "hide"}`}>
            <div className="header-categories-list">
                {/* <div className="settings-line">
                    <div className="settings-menu-btn"></div>
                </div> */}

                <div className="header-items main-items">
                    {mainItems.map(item =>
                        <SimpleLinkRenderer href={item.link} target={item.linkTarget} ariaLabel={item.title} className="header-item main-item" key={item.itemId}>
                            {item.sideMenuImage && <SiteSimpleImage src={item.sideMenuImage} alt={item.title} title={item.title} width={15} height={15} />}
                            <div className="item-title">{item.title}</div>
                        </SimpleLinkRenderer>
                    )}
                </div>

                <div className="separator"></div>

                <div className="header-items secondary-items">
                    {secondaryItems.map(item =>
                        <SimpleLinkRenderer href={item.link} target={item.linkTarget} ariaLabel={item.title} className="header-item secondary-item" key={item.itemId}>
                            <div className="item-title">{item.title}</div>
                        </SimpleLinkRenderer>
                    )}
                </div>

                <div className="header-items menu-settings">
                    <SimpleLinkRenderer href={menuSettings.privacyLink.link.publishedLink} target={termsLinkTarget} ariaLabel={'מדיניות פרטיות'} className="menu-settings-item">
                        <div className="menu-settings-title">מדיניות פרטיות</div>
                    </SimpleLinkRenderer>
                    <SimpleLinkRenderer href={menuSettings.termsLink.link.publishedLink} target={privacyLinkTarget} ariaLabel={'תנאי שימוש'} className="menu-settings-item">
                        <div className="menu-settings-title">תנאי שימוש</div>
                    </SimpleLinkRenderer>
                </div>

            </div>

            <div className="side-menu-black-menu">
                <SimpleLinkRenderer href="https://www.google.com/search?q=site%3A+www.calcalist.co.il" className="black-menu-link" title="חיפוש" target="_blank">
                    <div className="menu-icon search-icon" />
                    <span className="menu-title">חיפוש</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="/stock" className="black-menu-link" title="פורטל פיננסי">
                    <div className="menu-icon finance-icon" />
                    <span className="menu-title">פורטל פיננסי</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="/tv" className="black-menu-link" title="TV כלכליסט">
                    <div className="menu-icon tv-icon" />
                    <span className="menu-title">TV כלכליסט</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="/podcast" className="black-menu-link" title="פודקאסט כלכליסט">
                    <div className="menu-icon podcast-icon" />
                    <span className="menu-title">פודקאסט כלכליסט</span>
                </SimpleLinkRenderer>

                <SimpleLinkRenderer href="/redmail" className="black-menu-link" title="דואר אדום">
                    <div className="menu-icon red-mail-icon" />
                    <span className="menu-title red-mail-title">דואר אדום</span>
                </SimpleLinkRenderer>

                <div id="INDmenu-btn3" className="black-menu-link" title="אתר נגיש" accessKey="m" aria-labelledby="INDbtnTooltip" role="button"
                    onClick={clickAcessibilityBtn}>
                    <div className="menu-icon accessability-icon" />
                    <span className="menu-title">נגישות</span>
                </div>
            </div>

            <div className="side-menu-mask"></div>
        </div>
    )
}
