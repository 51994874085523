import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { HamburgerMenuItems } from "../../../calcalistWidgets/categoryHeader/components/hamburgerMenuList/menuList";
import { Logo } from "./siteRadionasHeaderComponenta";
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import * as map from "lodash/map"

interface MainNavProps {
    mainNavItems: HamburgerMenuItems[]
    onToggleMenu: () => void
    isOpened: boolean
    tabBgColor: string
    isSticky: boolean
    logoData: Logo
    onToggleSearch: () => void
}

export class MainNavHeaderComponenta extends React.Component<MainNavProps, {}> {
    private getSelectedClassName = (isSelected: boolean) => {
        if (isSelected) {
            return "selected"
        }
    }
    render() {
        const { mainNavItems, isOpened, onToggleMenu, tabBgColor, isSticky, logoData, onToggleSearch } = this.props
        let defaultColors = { tabBgColor: "#de1a1a" }
        const tabBackgruondStyle = tabBgColor ? tabBgColor : defaultColors.tabBgColor;

        return (
            <div className="mainNav" role="navigation" aria-label="Main Menu" style={{ backgroundColor: tabBackgruondStyle }}>
                {isSticky && <HyperLinkBuilder href={logoData.link} linkTarget={logoData.linkTarget} className="ynetLogoInSticky">
                <SiteSimpleImage src={logoData.url} alt={logoData.mediaTitle} title={logoData.mediaTitle} aria-hidden="true" aria-label="Page Logo" />
                    </HyperLinkBuilder>}
                <div className="navList">
                {map((mainNavItems), (item: HamburgerMenuItems, index: number) => <div key={index} >
                    <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} className={this.getSelectedClassName(item.isSelected)} style={{ color: isSticky ? "#fff": item.color , "--menuItemColor":  isSticky ? "#fff" : item.color }}>
                        <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    </HyperLinkBuilder>
                </div>)}</div>
                <div onClick={onToggleMenu} id="wcm_MenuDisplay"><span className="moreBtn" id="wcm_MenuDisplay_moreBtn">اخرى<span className={isOpened ? "moreIcon toClose" : "moreIcon toOpen"} /></span></div>
                <div onClick={onToggleSearch} id="wcm_SearchDisplay"  ><span id="wcm_MenuDisplay_searchIcon" className="searchIcon"></span ></div>

            </div>
        )
    }
}