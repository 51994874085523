import * as axios from "axios";
import { ArticleExtraData } from "articlePage/stateInterfaces";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { formatDate } from "../../../../siteWidgets/siteWidgetUtils";
import { TwentyFourSevenSlotList } from "./twentyFourSevenSlotList";
import { TickerSlotList } from "./tickerSlotList";

interface NewsFlashAndTwentyFourSevenMobileComponentaProps {
    componentaId?: string
    languageCode: string
    twentyFourSevenCategoryId: string
    numberOfVisibleDays: number
    twentyFourSevenBottomTitle: string
    twentyFourSevenFieldsDisplaySettings: IFieldDisplayData
    twentyFourSevenBottomLink: string
    tickerFieldsDisplaySettings: IFieldDisplayData
    tickerCategoryId: string
    tickerBottomLink: string
    tickerBottomTitle: string
    highlightingDuration:number
    mobileDisplayType:string
}

interface NewsFlashAndTwentyFourSevenMobileComponentaState {
    shouldDisplayTicker: boolean
    pageNumber: number
    twentyFourSevenArticles: ArticleExtraData[]
    tickerArticles: ArticleExtraData[]
    isFirstInterval: boolean
    shouldDisplayLoader: boolean
   
}

export class NewsFlashAndTwentyFourSevenMobileComponenta extends React.Component<NewsFlashAndTwentyFourSevenMobileComponentaProps, NewsFlashAndTwentyFourSevenMobileComponentaState> {
    public static siteScriptName = "SiteNewsFlashAndTwentyFourSevenComponenta"
    constructor(props) {
        super(props)
        this.state = {
            shouldDisplayTicker: true,
            pageNumber: 0,
            twentyFourSevenArticles: [],
            tickerArticles: [],
            isFirstInterval: true,
            shouldDisplayLoader: false,
        }
    }

    componentDidMount() {
        const { twentyFourSevenCategoryId, tickerCategoryId } = this.props;

        if (twentyFourSevenCategoryId) {
            this.getTwentyFourSevenArticles()
        }
        
        if (tickerCategoryId) {
            this.getTickerArticles()
            setInterval(() => {
                this.setState({ isFirstInterval: false })
                this.getTickerArticles()
            }, 60000);
        }
    }

    private getStartDate = (numberOfDays: number) => {
        const today = new Date();
        const startDate = new Date(today.getTime() - ((numberOfDays - 1) * 24 * 60 * 60 * 1000));
        return startDate;
    }

    private getTwentyFourSevenArticles = () => {
        const { componentaId, numberOfVisibleDays } = this.props
        const { pageNumber, twentyFourSevenArticles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        const formatedStartDate = formatDate(new Date(this.getStartDate(numberOfVisibleDays)), "YYYY-MM-DD");

        axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/pageNumber/${pageNumber}/startDate/${formatedStartDate}`)
            .then((res: any) => {
                const recivedArticles = res.data.data.twentyFourSeven.data as ArticleExtraData[];
                this.setState(
                    {
                        twentyFourSevenArticles: [...twentyFourSevenArticles, ...recivedArticles],
                        pageNumber: pageNumber + 1,
                    }
                );
            })
            .catch(err => console.error("error geting headline data:", err));
    }

    private getTickerArticles() {
        const { componentaId, highlightingDuration } = this.props;
        const { isFirstInterval } = this.state;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        const highlightDur = highlightingDuration ? highlightingDuration : 3;
        axiosInstance.get(`/iphone/json/api/auto_ticker/${componentaId}`)
            .then((res: any) => {
                this.setState({ tickerArticles: res.data.data.tickerAuto.data });
            }).then(() => {
                const zeroMinDiff = this.state.tickerArticles.some((article) => minutesDiff(article) === 0);
                const upToFiveMinDiff = this.state.tickerArticles.some((article) => minutesDiff(article) < highlightDur);

                if (isFirstInterval && upToFiveMinDiff) {
                    this.setState({ shouldDisplayLoader: true })
                    setTimeout(() => {
                        this.setState({ shouldDisplayLoader: false })
                    }, 3000)
                };

                if (!isFirstInterval && zeroMinDiff) {
                    this.setState({ shouldDisplayLoader: true })
                    setTimeout(() => {
                        this.setState({ shouldDisplayLoader: false })
                    }, 3000)
                };
            })
            .catch(err => console.error("error geting ticker data:", err))
    }


    private onToggleHandler(shouldDisplayTicker: boolean) {
        this.setState({ shouldDisplayTicker });
    }


    public render() {
        const { tickerFieldsDisplaySettings, twentyFourSevenFieldsDisplaySettings, twentyFourSevenBottomLink, tickerBottomLink, languageCode, twentyFourSevenBottomTitle, tickerBottomTitle, highlightingDuration, mobileDisplayType } = this.props;
        const { twentyFourSevenArticles, tickerArticles, shouldDisplayTicker, isFirstInterval } = this.state;
        const highlightDur = highlightingDuration ? highlightingDuration : 3;
        return (
            <div className={`newsFlashAndTwentyFourSevenMobileComponenta ${mobileDisplayType ? mobileDisplayType : ""}`}>
                <div className="tabsContainer">
                    <div className={`tabTitle ticker ${shouldDisplayTicker ? "active" : ""}`} onClick={() => this.onToggleHandler(true)}>
                        <div className="text">מבזקים</div>
                        <div className={`bottomBar ${shouldDisplayTicker ? "red" : ""}`}></div>
                    </div>
                    <div className={`tabTitle twentyFourSeven ${shouldDisplayTicker ? "" : "active"}`} onClick={() => this.onToggleHandler(false)}>
                        <div className="text">24/7 כל הכתבות</div>
                        <div className={`bottomBar ${shouldDisplayTicker ? "" : "blue"}`}></div>
                    </div>
                </div>
                <TickerSlotList
                    tickerArticles={tickerArticles}
                    tickerBottomLink={tickerBottomLink}
                    tickerFieldsDisplaySettings={tickerFieldsDisplaySettings}
                    languageCode={languageCode}
                    isFirstInterval={isFirstInterval}
                    shouldDisplayLoader={this.state.shouldDisplayLoader}
                    tickerBottomTitle={tickerBottomTitle}
                    highlightingDuration={highlightDur}
                    shouldDisplayTicker={shouldDisplayTicker} 
                    mobileDisplayType={mobileDisplayType}/>
                <TwentyFourSevenSlotList
                    twentyFourSevenArticles={twentyFourSevenArticles}
                    twentyFourSevenFieldsDisplaySettings={twentyFourSevenFieldsDisplaySettings}
                    twentyFourSevenBottomLink={twentyFourSevenBottomLink}
                    twentyFourSevenBottomTitle={twentyFourSevenBottomTitle}
                    shouldDisplayTicker={shouldDisplayTicker} />
            </div >
        )
    }
}

export const minutesDiff = (article: ArticleExtraData) => {
    return Math.floor((new Date().getTime() - article.dateUpdatedOnSite.getTime()) / (1000 * 60));
}