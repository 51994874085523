import * as classNames from "classnames"
import * as map from "lodash/map"
import { LinkTargetProperties } from "global/components/linkSettings/interfaces"
import { HyperLinkBuilder, SimpleLinkRenderer } from "../../../../../commonComponents/inline/hyperLink"

export interface HamburgerMenuItems {
    title: string
    link: string
    linkTarget: LinkTargetProperties
    color: string
    bgColor?: string
    isSelected?: boolean
    categoryId: string
}

interface MenuProps {
    menuItems: HamburgerMenuItems[]
    width: number
    isOpenedHam: boolean
}

export class MenuListComponenta extends React.Component<MenuProps, {}> {
    constructor(props) {
        super(props)

    }
    render() {
        const { menuItems, width, isOpenedHam } = this.props
        let style = { width: `${width}px` }
        const opened = isOpenedHam ? "opened" : "";
        return (

            <div className={`CalcalistMeniList ${opened}`} style={style} >
                <ul>
                    {map(menuItems, (item, i) =>
                        <li key={i}>
                            <HyperLinkBuilder href={item.link} linkTarget={item.linkTarget} style={{ color: item.color, background: item.bgColor }}>
                                <span dangerouslySetInnerHTML={{ __html: item.title }} />
                            </HyperLinkBuilder>
                        </li>)}
                </ul>
                <div className="bottomLinks">
                    <div className="linksWithIcons">
                      <SimpleLinkRenderer href="/home/0,7340,L-3857,00.html" target="_blank" className="c-icon-wrp hd-btn" title="ניוזלטר"><span className="t-newsletter icon-red"></span><span className="t-title adom ShualRegular">ניוזלטר</span></SimpleLinkRenderer>
                        <SimpleLinkRenderer href="//www.calcalist.co.il/podcast" className="c-icon-wrp hd-btn" title="פודקאסט"><span className="t-podcast icon-red"></span><span className="t-title adom ShualRegular">פודקאסט</span></SimpleLinkRenderer>
                        <SimpleLinkRenderer href="//www.calcalist.co.il/tv" className="c-icon-wrp hd-btn" title="כלכליסט TV"><span className="t-tv icon-red"></span><span className="t-title adom ShualRegular">כלכליסט TV</span></SimpleLinkRenderer>
                        <SimpleLinkRenderer href="https://www.youtube.com/calcalist" target="_blank" className="c-icon-wrp hd-btn" title="כלכליסט ביוטיוב"><span className="t-youtube icon-red"></span><span className="t-title adom ShualRegular">כלכליסט ביוטיוב</span></SimpleLinkRenderer>
                        <SimpleLinkRenderer href="https://z.calcalist.co.il/mvc/short/2018/Dreamcard/Home/Index" target="_blank" className="c-icon-wrp hd-btn" title="מינוי לעיתון"><span className="t-sub icon-red"></span><span className="t-title adom ShualRegular">מינוי לעיתון</span></SimpleLinkRenderer>
                        <SimpleLinkRenderer href="https://z.calcalist.co.il/mvc/long/2022/DigitalSubscription/Registration/Subscribe.aspx?type=evrit" target="_blank" className="c-icon-wrp hd-btn downloadApp" title="מינוי דיגיטלי"><span className="t-paperdigital icon-red"></span><span className="t-title adom ShualRegular">מינוי דיגיטלי</span></SimpleLinkRenderer>
                        <SimpleLinkRenderer href="https://www.calcalist.co.il/home/0,7340,L-3800,00.html" className="c-icon-wrp hd-btn last" title="שירות לקוחות"><span className="t-service icon-red"></span><span className="t-title adom ShualRegular">שירות לקוחות</span></SimpleLinkRenderer>

                    </div>
                </div>
            </div>
        )


    }
}