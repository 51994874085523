import { RadioProgramItem, DvrRadioPlayer } from "../../../radioWidgets/dvrRadioPlayer/dvrRadioPlayer"
import { ButtonRnederer } from "../../../radioWidgets/floatingPlayer/podcastPlayerButton";

interface CurrentShowItemProps {
    isMobileWeb: boolean
    currentShowIndex: number
    liveBroadCastSource: string
    radioScheduleList: RadioProgramItem[]
    handleCurrentShowIndexChange: (currentShowIndex: number) => void
}

export const CurrentShowItem = (props: CurrentShowItemProps) => {

    const { isMobileWeb, liveBroadCastSource, currentShowIndex, radioScheduleList, handleCurrentShowIndexChange } = props
    const { image, title, subTitle, anchor, startTime, endTime } = radioScheduleList[currentShowIndex]

    return (
        <div className="current-show-item">
            <div className="show-image-container">
                <img src={image} />

                {!isMobileWeb && <div className="live-label">راديو مباشر</div>}

                <DvrRadioPlayer
                    title={title}
                    site={"radionas"}
                    isSimplePlayer={true}
                    isMobileWeb={isMobileWeb}
                    liveBroadCastDVRSource={""}
                    startingIndex={currentShowIndex}
                    radioScheduleList={radioScheduleList}
                    liveBroadCastSource={liveBroadCastSource}
                    onCurrentShowIndexChange={handleCurrentShowIndexChange}
                    playerComponentaRenderer={(props) => (<ButtonRnederer {...props} className={"audio-play-button"} />)}
                />
            </div>

            <div className="current-show-titles">
                <div className="current-item-title">{title}</div>

                <div className="current-item-sub-title">{subTitle}</div>

                <div className="current-item-details">
                    <div className="anchor">{anchor}</div>

                    <div className="separator"></div>

                    <div className="show-time">{startTime} - {endTime}</div>
                </div>
            </div>

        </div>
    )
}