import { StampaComponenta } from "./stampaComponenta";
import { LogoComponenta } from "./logo/logoComponenta";
import { stampaType } from "../windows/logo/stampaSelector"
import { HamburgerMenuItems } from "./hamburgerMenuList/menuList";
import { LeftSideMenuComponenta } from "./leftSideMenuComponenta";
import { MainNavComponenta, MainNavitem } from "./mainNav/mainNavComponenta";
import { isWcmEditor } from "../../../../../config/serverConfig";
import { SiteSimpleImage, getImagesSrc } from "widgets/commonComponents/inline/siteSimpleImage"

interface SiteProps {
    logoProps: any
    mainNavItems: MainNavitem[]
    menuItems: HamburgerMenuItems[]
    stampa: stampaType
    width: number
    imagesDomain?:string
}

interface SiteState {
    isSticky: boolean
}

export class SiteCategoryHeaderComponenta extends React.Component<SiteProps, SiteState> {
    constructor(props) {
        super(props)
        this.state = {
            isSticky: false
        }
    }

    public static siteScriptName = "SiteCategoryHeaderComponenta"

    private activateStickyHandler = () => {
        let header = document.getElementById("CalcalistCategoryHeader");
        let sticky = this.getOffsetTop(header);

        document.addEventListener("scroll", (e) => {
            if (window.pageYOffset > sticky) {
                !this.state.isSticky ? this.setState({ isSticky: true }) : ''
            } else {
                this.state.isSticky ? this.setState({ isSticky: false }) : ''
            }
        })
    }

    private getOffsetTop = element => {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    componentDidMount() {
        this.activateStickyHandler()
    }

    render() {
        const { logoProps, mainNavItems, menuItems, stampa, width, imagesDomain } = this.props
        const { isSticky } = this.state

        return (
            <div className={isSticky ? "isSticky" : ''}>
                    <SiteSimpleImage src={getImagesSrc(imagesDomain, logoProps.url)} className="calcalistPrintlogo" />
                <div id="CalcalistCategoryHeader" className="CalcalistCategoryHeader no-print">
                    {!isWcmEditor() && <div className="calcalistHeaderBg no-print" />}
                    <StampaComponenta stampa={stampa} />
                    <LogoComponenta url={getImagesSrc(imagesDomain, logoProps.url)} mediaTitle={logoProps.mediaTitle} link={logoProps.link} linkTarget={logoProps.linkTarget} />
                    <MainNavComponenta items={mainNavItems} />
                    <LeftSideMenuComponenta menuItems={menuItems} width={width} />
                </div>
            </div>
        )
    }
}